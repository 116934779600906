<template>
    <v-card>
        <v-card-title>
            Klanten
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                id="maak-klant-button"
                to="/klanten/maak"
                style="margin-right: 20px;"
                outlined
                class="mobile-block"
                v-if="admin"
            >
                <v-icon>mdi-plus</v-icon>
                Maak klant aan
            </v-btn>
        </v-card-actions>
        <v-card-text>
            <v-data-table :search="search" :headers="headers" :options="tableOptions" :items="klanten">
                <template v-slot:top>
                    <v-text-field id="search-bar" class="mx-16" hide-details label="Zoek" v-model="search">
                    </v-text-field>
                </template>
                <template #item.naam="{ item }">
                    {{ item.klant_type === 'ondernemer' ? item.onderneming_naam : item.naam }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon v-if="!item.loading" name="edit-icon" class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon v-if="!item.loading && admin" name="delete-icon" @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                    <v-progress-circular
                        v-if="item.loading"
                        class="ma-auto"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </template>
                <template slot="no-data">
                    <v-progress-circular
                        :size="200"
                        :width="20"
                        style="margin: 60px;"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from '../../axios';
import Store from '../../store';

export default {
    data() {
        return {
            admin: Store.get('admin'),
            search: '',
            headers: [
                {
                    text: 'ID',
                    value: 'klantnummer',
                    sortable: true
                },
                {
                    text: 'Naam',
                    value: 'naam'
                },
                {
                    text: 'Email',
                    value: 'email'
                },
                {
                    text: 'Totaal',
                    value: 'taxatieCount'
                },
                {
                    text: 'Dit jaar',
                    value: 'taxatieCountThisYear'
                },
                {
                    text: 'Vorig jaar',
                    value: 'taxatieCountLastYear'
                },
                {
                    text: 'BSN nummer',
                    value: 'bsn_nummer'
                },
                {
                    text: 'Postcode',
                    value: 'postcode'
                },
                {
                    text: 'Plaats',
                    value: 'plaats'
                },
                {
                    text: 'Acties',
                    value: 'actions',
                    sortable: false
                }
            ],
            klanten: [],
            tableOptions: {
                itemsPerPage: 50
            }
        };
    },
    async mounted() {
        this.klanten = (await axios.get('/users/totals')).data;
    },
    methods: {
        editItem(item) {
            this.$router.push(`/klanten/maak/${item._id}`);
        },
        async deleteItem(item) {
            const deleteToggle = confirm(
                `Weet je zeker dat je de gebruiker ${
                    this.getKlant(item).naam
                } wilt verwijderen, dit verwijderd ook alle taxaties die aan deze gebruiker gekoppeld zitten?`
            );
            if (deleteToggle) {
                try {
                    item.loading = true;

                    await axios.delete(`/users/${item._id}`);

                    this.klanten = (await axios.get('/users/totals')).data;
                } catch (error) {
                    console.error(error);
                    alert('Er is iets fout gegaan');
                }
            }
        },
        getKlant(item) {
            return this.klanten.find(klant => klant._id === item._id);
        }
    },
    metaInfo: {
        title: 'Klanten'
    }
};
</script>
